import { useState, useCallback, useMemo, HTMLAttributes } from "react"
import {
	Autocomplete,
	AutocompleteRenderOptionState,
	FilterOptionsState,
	TextField,
	createFilterOptions,
} from "@mui/material"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { Dispatch, SetStateAction, useRef, useEffect } from "react"
import styled, { useTheme } from "styled-components"
import { default as MUIModal } from "@mui/material/Modal"
import SearchIcon from "@mui/icons-material/Search"
import Xmark from "../../icons/Xmark"
import { useTranslation } from "react-i18next"
import "./autocompleteStyles.css"
import useTechPack from "../../hooks/useTechPack"
import mediaQuery from "../../util/mediaQuery"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import CircleDot from "../../icons/CircleDot"
import useTechPackNavigationHandler from "../../hooks/useTechPackNavigationHandler"

interface Option {
	label: string
	value: string
	isSection: boolean
}

const SearchModal = ({
	options,
	searchTerm,
	setSearchTerm,
	handleTabKeydown,
}: {
	searchTerm: string
	options: Option[]
	setSearchTerm: Dispatch<SetStateAction<string>>
	handleTabKeydown: (handleClose: () => void, shift?: boolean) => void
}) => {
	const [optionIndex, setOptionIndex] = useState(-1)
	const [filteredOptions, setFilteredOptions] = useState<Option[]>([])
	const { showSearchModal, setShowSearchModal } = useTechPack()
	const { t } = useTranslation()
	const inputRef = useRef<HTMLInputElement>(null)
	const focusedOptionRef = useRef<HTMLLIElement>(null)
	const theme = useTheme()
	const { width } = useWindowDimensions()
	const searchValueTruncateLength = width > 800 ? 50 : width > 600 ? 40 : 30
	const styles = useMemo(
		() => ({
			backgroundColor: theme.techPackModalBackground,
			borderTopLeftRadius: "28px",
			borderTopRightRadius: "28px",
			borderBottomRightRadius: "0px",
			borderBottomLeftRadius: "0px",

			"& .MuiInputBase-input": {
				fontSize: "18px",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					border: "none",
				},
			},
			".MuiAutocomplete-popupIndicator": {
				display: "none",
			},
		}),
		[theme],
	)

	const handleClose = useCallback(() => {
		setShowSearchModal(false)
		setSearchTerm("")
		setOptionIndex(-1)
	}, [setSearchTerm, setShowSearchModal, setOptionIndex])

	const handleNavigate = useTechPackNavigationHandler(handleClose)

	useEffect(() => {
		setTimeout(() => {
			if (showSearchModal && inputRef.current) {
				// click instead of focus... really!?
				inputRef.current.click()
			}
		}, 100)
	}, [showSearchModal])

	useEffect(() => {
		if (optionIndex >= 0 && focusedOptionRef.current) {
			focusedOptionRef.current.scrollIntoView(false)
		}
	}, [optionIndex])

	const defaultFilterOptions = useMemo(() => createFilterOptions<Option>(), [])

	const filterOptions = useCallback(
		(options: Option[], state: FilterOptionsState<Option>) => {
			const newOptions = defaultFilterOptions(options, state)

			return newOptions
		},
		[defaultFilterOptions],
	)

	const handleAutocompleteKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement> & { defaultMuiPrevented?: boolean }) => {
			const handledCases = ["Tab", "ArrowDown", "ArrowUp", "ArrowRight", "Enter"]

			if (handledCases.includes(e.key)) {
				e.preventDefault()
			}
			if (filteredOptions.length === 0) {
				return
			}
			switch (e.key) {
				case "Tab":
					if (searchTerm.length === 0) {
						handleTabKeydown(handleClose, e.shiftKey)
					} else if (e.shiftKey) {
						const newIndex = optionIndex - 1
						setOptionIndex(newIndex >= 0 ? newIndex : filteredOptions.length - 1)
					} else {
						setOptionIndex(optionIndex + 1)
					}
					break
				case "ArrowDown":
					setOptionIndex(optionIndex + 1)
					break
				case "ArrowUp":
					const newIndex = optionIndex - 1
					setOptionIndex(newIndex >= 0 ? newIndex : filteredOptions.length - 1)
					break
				case "ArrowRight":
					const newSearchTerm =
						filteredOptions[optionIndex >= 0 ? optionIndex % filteredOptions.length : 0]
					setSearchTerm(newSearchTerm.label)
					break
				case "Enter":
					if (filteredOptions.length) {
						const selectedValue =
							optionIndex >= 0
								? filteredOptions[optionIndex % filteredOptions.length]
								: filteredOptions[0]
						const section = selectedValue.value.split(">").pop() || ""
						if (section && section !== "") {
							handleNavigate(section.replace(/ /g, ""))
						}
					}
					break
			}
		},
		[
			filteredOptions,
			handleNavigate,
			optionIndex,
			setSearchTerm,
			searchTerm,
			handleClose,
			handleTabKeydown,
		],
	)

	const renderOption = useCallback(
		(
			props: HTMLAttributes<HTMLLIElement>,
			option: Option,
			{ inputValue }: AutocompleteRenderOptionState,
		) => {
			const matches = match(option.label, inputValue, { insideWords: true })
			const parts = parse(option.label, matches)
			const section = option.value || ""
			const key = Math.random().toString(36).substring(2) + new Date().getTime().toString(36)
			const noHover = option.value === "noMatch" || option.value === ""
			const tabbedOption =
				option.value !== "" &&
				filteredOptions.length > 0 &&
				filteredOptions[optionIndex >= 0 ? optionIndex % filteredOptions.length : 0].label ===
					option.label

			return (
				<StyledListItemContainer noHover={noHover} highlight={tabbedOption} key={key}>
					{!option.isSection && option.label && (
						<div style={{ marginBottom: 2, marginLeft: 30 }}>
							<CircleDot size={8} color={theme.techPackIcon} />
						</div>
					)}
					<ListItem
						{...props}
						data-cy={`option_${option.label}`}
						ref={tabbedOption ? focusedOptionRef : undefined}
						onSelect={() => section && handleNavigate(section.replace(/ /g, ""))}
						onClick={() => section && handleNavigate(section.replace(/ /g, ""))}
						noHover={noHover}
					>
						<div>
							{parts.map((part, index) => {
								if (parts.length > 2) {
									if (
										!part.highlight &&
										index === 0 &&
										option.label.length > searchValueTruncateLength &&
										part.text.length > 18
									) {
										return <span key={index}>{"..." + t(part.text.slice(-15))}</span>
									} else {
										return (
											<span
												key={index}
												style={{
													backgroundColor: part.highlight ? theme.techPackAccent : "",
												}}
											>
												{t(part.text)}
											</span>
										)
									}
								} else {
									return (
										<span
											key={index}
											style={{
												backgroundColor: part.highlight ? theme.techPackAccent : "",
											}}
										>
											{part.text}
										</span>
									)
								}
							})}
						</div>
					</ListItem>
				</StyledListItemContainer>
			)
		},
		[filteredOptions, handleNavigate, optionIndex, searchValueTruncateLength, t, theme],
	)

	useEffect(() => {
		/* Not super optimized as it will filter options twice, but this is the least invasive solution to 
		keeping component state hierarchy. Small enough max data set to not matter, but a custom Autocomplete
		might be ideal later down the road when refining. */
		const newOptions = filterOptions(options, {
			inputValue: searchTerm,
			getOptionLabel: (option: Option) => option.label,
		})

		const updateOptions = !(
			filteredOptions.length === newOptions.length &&
			newOptions.every(
				(option, i) =>
					option.label === filteredOptions[i].label &&
					option.value === filteredOptions[i].value &&
					option.isSection === filteredOptions[i].isSection,
			)
		)
		if (updateOptions) {
			setFilteredOptions(newOptions)
		}
	}, [searchTerm, options, filterOptions, filteredOptions])

	return (
		<MUIModal open={showSearchModal} onClose={handleClose} sx={{ width: "100%" }}>
			<Modal>
				<Autocomplete
					disableClearable
					disablePortal
					autoComplete
					open
					noOptionsText={t("noMatch_plural")}
					ref={inputRef}
					options={options}
					filterOptions={filterOptions}
					sx={styles}
					inputValue={searchTerm}
					onInputChange={(_event, newInputValue) => {
						setSearchTerm(newInputValue)
						setOptionIndex(-1)
					}}
					onKeyDown={handleAutocompleteKeyDown}
					renderOption={renderOption}
					renderInput={params => (
						<InputContainer data-cy="techPack_searchBox-Open">
							<StyledSearchIcon />
							<TextField
								{...params}
								inputProps={{
									...params.inputProps,
									value:
										optionIndex === -1
											? searchTerm
											: filteredOptions[optionIndex % filteredOptions.length].label,
								}}
								placeholder="Search"
								onKeyDown={e => {
									if (e.key === "Escape") handleClose()
								}}
								autoFocus
							/>
							<ClearContainer data-cy="techPack_searchBox-CloseX" onClick={handleClose}>
								<Xmark size={24} color={theme.techPackIcon} />
							</ClearContainer>
						</InputContainer>
					)}
				/>
			</Modal>
		</MUIModal>
	)
}

const Modal = styled.div`
	display: flex;
	justify-content: center;
	margin: 35px auto 0;
	outline: none;
	width: 100% !important;
	max-width: 560px !important;

	.MuiAutocomplete-noOptions {
		background-color: ${({ theme }) => theme.techPackModalBackground};
		color: ${({ theme }) => theme.techPackText};
	}

	${mediaQuery("md")`
		margin-top: 160px;
		padding: 0px;
	`}
	${mediaQuery("sm")`
		margin-top: 130px;
	`}
`
const StyledListItemContainer = styled.div<{ noHover: boolean; highlight: boolean }>`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.techPackHighlightText};

	background-color: ${({ highlight, theme }) =>
		highlight ? theme.techPackHeaderButtonBg : theme.techPackModalBackground} !important;

	&:hover {
		background-color: ${({ noHover, theme }) =>
			noHover ? theme.techPackModalBackground : theme.techPackHeaderButtonBg} !important;
		cursor: ${({ noHover }) => (noHover ? "default" : "pointer")} !important;
	}

	.MuiAutocomplete-option.Mui-focused {
		color: ${({ theme }) => theme.techPackText} !important;
	}

	${mediaQuery("md")`
		padding: 0px;
	`}
`

const ListItem = styled.li<{ noHover: boolean }>`
	height: auto;
	min-height: 44px;
	width: 100% !important;
	color: ${({ theme }) => theme.techPackText};

	> div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	${noHover => noHover && "height: 48px !important;"}
`
const InputContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 0px 15px;

	.MuiInputBase-input {
		color: ${({ theme }) => theme.techPackText};
	}
`
const ClearContainer = styled.div`
	cursor: pointer;
	margin-top: 4px;
`
const StyledSearchIcon = styled(SearchIcon)`
	font-size: 34px !important;
	color: ${({ theme }) => theme.techPackIcon} !important;
`

export default SearchModal
