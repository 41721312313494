import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FormField from "../../atoms/FormField"
import TextAreaWithLabel from "../../molecules/TextAreaWithLabel"
import { useTranslation } from "react-i18next"
import mediaQuery from "../../../util/mediaQuery"
import Select from "../../atoms/Select"
import Chip from "../../atoms/Chip"
import VenueContacts from "./VenueContacts"
import useVenueTypes from "../../../hooks/useVenueTypes"
import { useEffect, useState } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Paper from "../../atoms/Paper"
import countries from "../../../util/constants/COUNTRIES"
import useProduction from "../../../hooks/useProduction"
import LoadingPage from "../../views/LoadingPage"
import VenueLogo from "../../molecules/VenueLogo"
import { isValidLatLong, formatLatLong } from "../../../util/latLong"
import TimeStamp from "../../molecules/TimeStamp"
import { Switch } from "@mui/material"
import { extractTimeStampData, normalizeTimezone } from "../../../util/dateFunctions"
import FilteredSelect from "../../../components/atoms/FilteredSelect"
import statesAndTerritories from "../../../util/constants/STATES_TERRITORIES"

const PreviousNames = () => {
	const {
		state: { venue },
		setState,
	} = useProduction()
	const { t } = useTranslation()

	const addPreviousName = (name: string) => {
		setState((s: ProductionStateInterface) => ({
			...s,
			venue: {
				...s.venue,
				previousNames: [...(s.venue.previousNames || []), { name }],
			},
		}))
	}

	const removePreviousName = (names: string[]) => {
		setState((s: ProductionStateInterface) => ({
			...s,
			venue: {
				...s.venue,
				previousNames: s.venue.previousNames
					? s.venue.previousNames.filter(p => names.indexOf(p.name) !== -1)
					: [],
			},
		}))
	}

	return (
		<Autocomplete
			multiple
			freeSolo
			autoSelect
			disableClearable
			onChange={(event, updatedPreviousNames, eventType) => {
				if (eventType === "removeOption") {
					removePreviousName(updatedPreviousNames)
				} else {
					addPreviousName(updatedPreviousNames[updatedPreviousNames.length - 1])
				}
			}}
			open={false}
			value={venue.previousNames?.map(p => p.name) || []}
			options={venue.previousNames?.map(p => p.name) || []}
			renderTags={(value: readonly string[], getTagProps) =>
				value.map((option: string, index: number) => (
					<Chip label={option} {...getTagProps({ index })} />
				))
			}
			renderInput={params => (
				<FormField
					{...params}
					label={t("previousNames")}
					placeholder={t("addNew")}
					data-cy="production-PreviousNames-Field"
				/>
			)}
		/>
	)
}

interface StateCountryTerritories {
	id: string
	name: string
}

const VenueInformation = () => {
	const { t } = useTranslation()
	const { venueFieldChanges } = useProduction()

	// Handle both lat and long as one 'most recent' value
	const extractMostRecentLatLng = () => {
		const venueData = venueFieldChanges?.venue
		if (!venueData) return null
		const latitudeData = venueData["latitude"]
		const longitudeData = venueData["longitude"]
		if (!latitudeData && !longitudeData) return null

		// Compare the dates of the most recent latitude and longitude changes
		const mostRecentData =
			latitudeData && longitudeData
				? new Date(normalizeTimezone(latitudeData.actionDate)) >=
				  new Date(normalizeTimezone(longitudeData.actionDate))
					? latitudeData
					: longitudeData
				: latitudeData || longitudeData
		return {
			modifiedBy: mostRecentData.user?.name?.displayName,
			modifiedDate: normalizeTimezone(mostRecentData.actionDate),
		}
	}

	// not yet implemented - show Missing Information section for required fields in Venue Information form
	const [isMissingData] = useState(false)

	const {
		state: { venue, disableSaveButton },
		setState,
		fetchPlacesAPI,
		updateVenueUrl,
		updateVenuePublicNotes,
		setTimeStampEnabled,
		timeStampEnabled,
	} = useProduction()

	const editVenue = (update: object) =>
		setState((s: ProductionStateInterface) => ({ ...s, venue: { ...s.venue, ...update } }))

	const [venueTypes] = useVenueTypes()

	const countryCodes: StateCountryTerritories[] = countries.map(country => ({
		id: country,
		name: t(`country${country}`, { ns: "countries" }),
	}))
	const stateTerritoryCodes: StateCountryTerritories[] = statesAndTerritories.map(
		stateTerritory => ({
			id: stateTerritory,
			name: t(`${stateTerritory}`, { ns: "statesTerritories" }),
		}),
	)

	const disableSave = () => {
		if (!disableSaveButton) {
			setState((s: ProductionStateInterface) => ({ ...s, disableSaveButton: true }))
		}
	}

	const enableSave = () => {
		if (disableSaveButton) {
			setState((s: ProductionStateInterface) => ({ ...s, disableSaveButton: false }))
		}
	}

	//prevent save if fields invalid
	const isLatitudeInvalid = !isValidLatLong(venue.latitude, true)
	const isLongitudeInvalid = !isValidLatLong(venue.longitude, false)
	const isVenueNameInvalid = venue.name.trim().length === 0
	useEffect(() => {
		if (isLatitudeInvalid || isLongitudeInvalid || isVenueNameInvalid) {
			disableSave()
		} else {
			enableSave()
		}
		// eslint-disable-next-line
	}, [isLatitudeInvalid, isLongitudeInvalid, isVenueNameInvalid])

	const [defaultTimeStampValue] = useState(timeStampEnabled)

	// Reduce arguments for each extractTimeStampData call
	const venueTimeStamp = (fieldName: any) =>
		extractTimeStampData(fieldName, venueFieldChanges, "venue")

	return (
		<LoadingPage isLoading={!venueTypes.length}>
			<FormContainer>
				<TypeLogoContainer>
					<HeaderContainer>
						<h2>{t("generalVenueInformation")}</h2>
						<ShowTimeStampContainer>
							<p>{t("showTimeStamps")}</p>
							<StyledSwitch
								data-cy="production-TimestampToggle"
								defaultChecked={defaultTimeStampValue}
								onClick={() => setTimeStampEnabled(!timeStampEnabled)}
							/>
						</ShowTimeStampContainer>
						{isMissingData && (
							<div>
								{t("thisSectionIsMissingNecessaryInformation")}
								<StyledWarningIcon icon="triangle-exclamation" />
							</div>
						)}
					</HeaderContainer>
					<VenueLogo />
				</TypeLogoContainer>
				<TypeContainer className="select-container">
					<Select
						label={t("venueType")}
						items={venueTypes.map(v => ({ id: v.translationKey, name: v.translatedValue }))}
						onChange={e => editVenue({ venueTypeId: e.target.value })}
						selected={venue?.venueTypeId || ""}
						data-cy="production-VenueType-Field"
					/>
				</TypeContainer>
				<TimeStamp marginTop={-45} {...venueTimeStamp("venueTypeId")} />
				<InputsContainer>
					<div>
						<FormField
							error={isVenueNameInvalid}
							label={t("venueName")}
							value={venue.name || ""}
							required
							onChange={e => editVenue({ name: e.target.value })}
							data-cy="production-VenueName-Field"
						/>
						<TimeStamp {...venueTimeStamp("name")} />

						<FormField
							label={t("addressLine1")}
							value={venue?.addressLine1 || ""}
							onChange={e => {
								editVenue({ addressLine1: e.target.value })
								disableSave()
							}}
							onBlur={fetchPlacesAPI}
							data-cy="production-AddressLine1-Field"
						/>
						<TimeStamp {...venueTimeStamp("addressLine1")} />

						<FormField
							label={t("addressLine2")}
							value={venue?.addressLine2 || ""}
							onChange={e => {
								editVenue({ addressLine2: e.target.value })
								disableSave()
							}}
							onBlur={fetchPlacesAPI}
							data-cy="production-AddressLine2-Field"
						/>
						<TimeStamp {...venueTimeStamp("addressLine2")} />

						<FormField
							label={t("city")}
							value={venue?.city || ""}
							onChange={e => {
								editVenue({ city: e.target.value })
								disableSave()
							}}
							onBlur={fetchPlacesAPI}
							data-cy="production-City-Field"
						/>
						<TimeStamp {...venueTimeStamp("city")} />

						<FilteredSelect
							items={stateTerritoryCodes}
							selected={venue?.state || ""}
							onChange={e => editVenue({ state: e })}
							label={t("state")}
							padding="10px"
							labelPadding="-7px"
							onBlur={fetchPlacesAPI}
							data-cy="production-State-Dropdown"
						/>
						<TimeStamp {...venueTimeStamp("state")} />

						<FilteredSelect
							items={countryCodes}
							selected={venue?.country || ""}
							onChange={e => editVenue({ country: e })}
							label={t("country")}
							padding="10px"
							labelPadding="-7px"
							data-cy="production-Country-Dropdown"
						/>
						<TimeStamp {...venueTimeStamp("country")} />

						<FormField
							label={t("postalCode")}
							value={venue?.zip || ""}
							onChange={e => editVenue({ zip: e.target.value })}
							onBlur={fetchPlacesAPI}
							data-cy="production-PostalCode-Field"
						/>
						<TimeStamp {...venueTimeStamp("zip")} />
					</div>
					<div>
						<FormField
							label={t("venueWebsite")}
							value={venue?.urls.find(url => url.label === "primary")?.url || ""}
							onChange={e => updateVenueUrl(e.target.value)}
							data-cy="production-VenueWebsite-Field"
						/>
						<TimeStamp {...venueTimeStamp("urls.0.url")} />

						<div className="latlong-container">
							<FormField
								error={isLatitudeInvalid}
								label={t("latitude")}
								value={venue.latitude || ""}
								onChange={e => {
									editVenue({ latitude: e.target.value })
								}}
								onBlur={() => editVenue({ latitude: formatLatLong(venue.latitude) })}
								data-cy="production-Latitude-Field"
							/>
							<FormField
								error={isLongitudeInvalid}
								label={t("longitude")}
								value={venue.longitude || ""}
								onChange={e => {
									editVenue({ longitude: e.target.value })
								}}
								onBlur={() => editVenue({ longitude: formatLatLong(venue.longitude) })}
								data-cy="production-Longitude-Field"
							/>
						</div>
						<TimeStamp {...extractMostRecentLatLng()} />

						<FormField
							label={t("ageRequirement")}
							value={venue?.ageRequirement || ""}
							onChange={e => editVenue({ ageRequirement: e.target.value })}
							data-cy="production-AgeRequirement-Field"
						/>
						<TimeStamp {...venueTimeStamp("ageRequirement")} />

						<PreviousNames data-cy="production-PreviousNames-Field" />
						<TimeStamp {...venueTimeStamp("previousNames.0")} />

						<TextAreaWithLabel
							minRows={3}
							placeholder={t("publicNotes")}
							label={t("publicNotes")}
							value={venue?.notes.find(n => n.label === "public")?.notes || ""}
							onChange={e => updateVenuePublicNotes(e.target.value)}
							data-cy="production-PublicNotes-Field"
						/>
						<TimeStamp {...venueTimeStamp("notes.0.notes")} />
					</div>
					<div>
						<VenueContacts />
					</div>
				</InputsContainer>
			</FormContainer>
		</LoadingPage>
	)
}

const ShowTimeStampContainer = styled.div`
	display: flex;
	padding-top: 0.6rem;
`

const StyledSwitch = styled(Switch)`
	margin-left: 0.5rem;
	margin-top: -0.5rem;
`

const InputsContainer = styled.div`
	display: flex;
	gap: 6rem;

	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 2rem;
	}

	& > div {
		flex: 1;
		display: flex;
		gap: 2rem;
		flex-direction: column;
	}

	& .latlong-container {
		display: flex;
		gap: 1rem;

		& div {
			flex: 1;
		}
	}
`
const TypeLogoContainer = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	${mediaQuery("md")`
		flex-direction: column;
	`}
`
const TypeContainer = styled.div`
	width: 32%;
	margin-top: -100px;
	${mediaQuery("md")`
		margin-top: -20px;
		width: 100%;
	`}
`

const HeaderContainer = styled.div`
	width: 100%;
`

const FormContainer = styled(Paper)`
	background-color: ${({ theme }) => theme.cardColor} !important;
	display: flex;
	flex-direction: column;
	gap: 3rem;
	margin-bottom: 2rem;
	padding: 2rem;

	h2 {
		font-family: "RobotoCondensed-Bold" !important;
		margin: 0;
		padding: 0;
	}

	${mediaQuery("md")`
		margin: 5% 0;
		padding: 5%;
	`}

	> div {
		color: ${({ theme }) => theme.text};
	}
`

const StyledWarningIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.linkColor};
	width: auto;
	height: 1.5em;
`

export default VenueInformation
