import useTechPack from "../../../hooks/useTechPack"
import { FC, RefObject, useRef, useState } from "react"
import styled, { useTheme } from "styled-components"
import mediaQuery, { breakpoints } from "../../../util/mediaQuery"
import Link from "../../../icons/Link"
import Hamburger from "../../../icons/Hamburger"
import EventricLogo from "../../../logos/Eventric_Logo.jpg"
import EventricLogoDark from "../../../logos/Eventric_Logo_Dark_Mode.svg"
import TechPackSearch from "../../molecules/TechPackSearch"
import LinkButton from "../../atoms/LinkButton"
import CopyToClipboard from "react-copy-to-clipboard"
import { toast } from "react-toastify"
import { Trans, useTranslation } from "react-i18next"
import { dateFormatToLocale } from "../../../util/dateFunctions"
import { SwipeableDrawer } from "@mui/material"
import MobileNavigation from "./Navigation/MobileNavigation"
import useDarkMode from "../../../hooks/useDarkMode"

const Header: FC<{
	contentRef: RefObject<HTMLDivElement>
}> = ({ contentRef }) => {
	const {
		venue: { name: venueName },
		stage: { name: stageName },
		modifiedDate,
		venueLogoUrl,
		stage,
	} = useTechPack()
	const { t } = useTranslation()
	const nextTabRef = useRef<HTMLAnchorElement>(null)
	const prevTabRef = useRef<HTMLButtonElement>(null)
	const [showNav, setShowNav] = useState(false)

	const handleTabKeydown = (handleClose: () => void, shift?: boolean) => {
		const getNextTabbable = (shift?: boolean) => {
			const tabbableElements = [] as Element[]
			contentRef.current
				?.querySelectorAll("a, area, button, frame, iframe, input, object, select, textarea")
				.forEach(el => {
					if (el.getAttribute("tabindex") === "-1") {
						return
					}
					if (el.tagName === "A" || el.tagName === "Area") {
						if (el.hasAttribute("href")) {
							tabbableElements.push(el)
						}
					} else {
						tabbableElements.push(el)
					}
				})
			if (tabbableElements.length === 0) {
				return null
			}
			return tabbableElements[shift ? tabbableElements.length - 1 : 0] as HTMLElement
		}

		handleClose()
		if (window.innerWidth > breakpoints["md"]) {
			if (shift) {
				prevTabRef.current?.focus()
			} else {
				nextTabRef.current?.focus()
			}
		} else {
			const nextTabbable = getNextTabbable(shift)
			if (nextTabbable) {
				nextTabbable.focus()
			}
		}
	}

	const handleBurgerClick = () => {
		setShowNav(true)
	}

	const theme = useTheme()
	const { darkMode } = useDarkMode()

	return (
		<>
			<SwipeableDrawer
				anchor="top"
				open={showNav}
				onClose={() => setShowNav(false)}
				onOpen={() => setShowNav(true)}
				id="findMePlease"
			>
				<MobileNavigation setShowNav={setShowNav} />
			</SwipeableDrawer>
			<UpdatedByContainer>
				<UpdatedByText>
					<Trans
						i18nKey="lastUpdatedOnBy"
						values={{
							updateAuthor: stage.metadata?.venueModifiedByName
								? stage.metadata.venueModifiedByName
								: t("masterTourStaff"),
							date: dateFormatToLocale(modifiedDate || ""),
							role: stage.metadata?.venueModifiedByTitle
								? stage.metadata.venueModifiedByTitle
								: t("administrator"),
						}}
						components={{ b: <span style={{ fontFamily: "Roboto-Black" }} /> }}
						shouldUnescape
					/>
				</UpdatedByText>
				<CopyToClipboard
					text={window.location.origin + window.location.pathname}
					onCopy={() => toast.success(t("copiedToClipboard"))}
				>
					<CopyLinkButton data-cy="techPack-header_CopyLink" ref={prevTabRef}>
						<Link size={20} color={theme.techPackHeaderButtonText} />
						<CopyLinkText>{t("copyLink")}</CopyLinkText>
					</CopyLinkButton>
				</CopyToClipboard>

				<CopyToClipboard
					text={window.location.origin + window.location.pathname}
					onCopy={() => toast.success(t("copiedToClipboard"))}
				>
					<StyledCircleLinkButton>
						<LinkButton
							color={theme.techPackIconBackground}
							borderColor={theme.techPackIconBackground}
							textToCopy={`${window.location.origin + window.location.pathname}`}
						/>
					</StyledCircleLinkButton>
				</CopyToClipboard>
			</UpdatedByContainer>
			<SubContainer>
				<LogoVenueStageContainer>
					<ImageContainer isVenueLogo={!!venueLogoUrl}>
						{venueLogoUrl ? <ResponsiveImage src={venueLogoUrl} alt="venue" /> : null}
					</ImageContainer>
					<InfoContainer>
						<VenueNameText data-cy="techPack-header_VenueName">{venueName}</VenueNameText>
						<StageNameText data-cy="techPack-header_StageName">{stageName}</StageNameText>
					</InfoContainer>
				</LogoVenueStageContainer>
				<SearchContainer>
					<TechPackSearch handleTabKeydown={handleTabKeydown} data-cy="techPack_searchBox-Closed" />
				</SearchContainer>
				<HamburgerContainer onClick={handleBurgerClick}>
					<Hamburger size={30} color={theme.techPackTextHeadings} />
				</HamburgerContainer>
				<LogoContainer>
					<a
						href="https://www.eventric.com/"
						rel="noreferrer"
						target="_blank"
						data-cy="techPack-header_EventricLogo"
						ref={nextTabRef}
					>
						<img
							src={darkMode ? EventricLogoDark : EventricLogo}
							alt="Eventric Logo"
							width="199px"
							height="55px"
						/>
					</a>
				</LogoContainer>
			</SubContainer>
		</>
	)
}

const LogoVenueStageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	${mediaQuery("md")`
		max-width: 80%;
`}
`

const SubContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	border-bottom: 2px solid ${({ theme }) => theme.techPackBorder};
	display: grid;
	grid-template-columns: minmax(33%, auto) minmax(auto, 660px) minmax(33%, auto);
	align-items: center;
	left: 0;
	height: 120px;
	overflow: hidden;
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 1;

	@media print {
		display: none;
	}

	${mediaQuery("md")`
		display: flex;
		justify-content: space-between;
	`}

	${mediaQuery("sm")`
		height: 90px;
	`}
`
const SearchContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`

const UpdatedByContainer = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.techPackHeaderBg};
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	${mediaQuery("sm")`
		height: 67px;
	`}
`
const UpdatedByText = styled.p`
	font-family: "Signika-Regular" !important;
	font-size: 16px;
	text-align: center;
	margin: 0;
	color: ${({ theme }) => theme.techPackHeaderText};
	margin-right: 60px;
	margin-left: 60px;

	${mediaQuery("md")`
		font-size: 16px;
	`};
`
const CopyLinkButton = styled.button`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 122px;
	height: 35px;
	background: ${({ theme }) => theme.techPackHeaderButtonBg};
	border: 1px solid ${({ theme }) => theme.techPackHeaderButtonBg};
	color: ${({ theme }) => theme.techPackHeaderButtonText};
	border-radius: 5px;
	right: 35px;
	cursor: pointer;
	padding: 10px;

	${mediaQuery("md")`
		display: none;
	`}
`

const CopyLinkText = styled.p`
	font-family: "Signika-Regular" !important;
	margin-left: 8px;
	font-size: 1rem;
`

const StyledCircleLinkButton = styled.div`
	display: none;
	position: absolute;
	right: 10px;
	cursor: pointer;

	${mediaQuery("md")`
		display: block;
	`}
`

const HamburgerContainer = styled.div`
	display: none;
	cursor: pointer;
	margin-right: 20px;

	${mediaQuery("md")`
		display: flex;
		justify-content: flex-end;
	`}
`

const ImageContainer = styled.div<{ isVenueLogo?: boolean }>`
	margin-right: 2rem;
	height: ${({ isVenueLogo }) => (isVenueLogo ? "105px" : "auto")};
	margin-left: 30px;

	${mediaQuery("sm")`
		margin-right: 1rem;
		margin-left: 5px;
		height: 74px;
	`}
`
const ResponsiveImage = styled.img<{ width?: number }>`
	height: 105px;
	width: auto;
	aspect-ratio: 1;
	object-fit: contain;

	${mediaQuery("sm")`
		height: 74px;
	`}
`
const InfoContainer = styled.div`
	margin-right: 10px;
	overflow: hidden;

	${mediaQuery("sm")`
		margin-right: 10px;
	`}
`
const VenueNameText = styled.h1`
	font-family: "Roboto-Black";
	font-size: 18px;
	margin: 0;
	margin-bottom: 5px;
	line-height: 21px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${({ theme }) => theme.techPackText};

	${mediaQuery("sm")`
		font-size: 15px;
		margin-bottom: 0;
	`}
`
const StageNameText = styled.p`
	font-size: 16px;
	margin: 0;
	line-height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${({ theme }) => theme.techPackText};

	${mediaQuery("sm")`
		font-size: 12px;
	`}
`

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 40px;

	${mediaQuery("md")`
		display: none;
	`}
`

export default Header
